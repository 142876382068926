import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '主页',
    meta: {
      hideInMenu: true,
    },
    component: HomeView
  },

  {
    path: '/test',
    name: '测试',
    meta: {
      hideInMenu: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/test/TestView.vue')
  },
  {
    path: '/fileHandle/:type/',
    name: '文件处理',
    children: [
      // {
      //   path: "/fileHandle/pdf2word",
      //   name: "pdf2word",
      //   component: pdf2word,
      // },
      // {
      //   path: "/fileHandle/word2pdf",
      //   name: "word2pdf",
      //   component: word2pdf,
      // },
    ],
    meta: {
      hideInMenu: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/file/ConvertFile.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
