<template>
  <div class="home" style="height: 100%;width: 100%;background-color: #f9f9f9;">
    <div class="banner" style="background-color: #ffffff;">
      <div class="banner-main">
        <div style="padding-top: 25px;height: 250px;">
          <img style="height: 250px;width: 250px" src="@/assets/snailLogo.png"/>
        </div>
        <div style="position: relative;height: 70px;margin: 30px">
          <h1 key="h1" style="font-size: 52px;color: #213547;opacity: .86">ArSnail</h1>
<!--          <p class="main-info" key="p" >-->
<!--            全新一代数据可视化解决方案，致力于提供一套简单方便、专业可靠、无限可能的数据可视化最佳实践。-->
<!--          </p>-->
          <p class="main-info" key="p">
            如同细水长流，每一刻都蕴含着无尽的温馨与感动。从清晨的第一缕阳光到夜晚的满天繁星，每一刻都记录着我们的成长与变化。那些微笑的瞬间、那些心动的时刻，都化作生活的珍贵片段，温暖着我们的心灵。
          </p>
        </div>

        <div style="margin: 10px">
<!--          <lay-ripple>-->
<!--            <lay-button type="primary" style="margin: 10px" @click="jumpToContainers">往下开始使用</lay-button>-->
<!--          </lay-ripple>-->
          <lay-ripple>
            <lay-button style="margin: 10px">关于我们</lay-button>
          </lay-ripple>
        </div>
        <div style="font-size: 12px;font-style: normal">
          <span>当前版本: 1.0.0</span>
        </div>
      </div>
    </div>
<!--    <div ref="targetElement" style="background-color: #f9f9f9;margin-top: 100px">-->
<!--      <div >-->
<!--        <h1 style="font-size: 25px;color: #213547;opacity: .86">丰富的开放小工具</h1>-->
<!--      </div>-->
<!--      <div>-->
<!--        <lay-row space="10" style="margin: 30px">-->
<!--          <lay-col md="6"><div class="grid-demo"></div></lay-col>-->
<!--          <lay-col md="6" class="card-file">-->
<!--            <div class="grid-demo">-->
<!--              <lay-card shadow="hover" style="cursor: pointer;" @click="fileHandle('pdf2word')">-->
<!--                <div style="text-align:center">-->
<!--                  <img src="@/assets/icon/convert-pdf-to-word.png">-->
<!--                  <h3>将PDF文件转换成可编辑的Word文档（DOC，DOCX）</h3>-->
<!--                </div>-->
<!--              </lay-card>-->
<!--            </div>-->
<!--          </lay-col>-->
<!--          <lay-col md="6" class="card-file">-->
<!--            <div class="grid-demo">-->
<!--              <lay-card shadow="hover" style="cursor: pointer;" @click="fileHandle('word2pdf')">-->
<!--                <div style="text-align:center">-->
<!--                  <img src="@/assets/icon/convert-word-to-pdf.png">-->
<!--                  <h3>将Microsoft Word文档格式转换成PDF文件格式</h3>-->
<!--                </div>-->
<!--              </lay-card>-->
<!--            </div>-->
<!--          </lay-col>-->
<!--          <lay-col md="6"><div class="grid-demo"></div></lay-col>-->
<!--        </lay-row>-->
<!--      </div>-->

<!--    </div>-->

  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import {ref} from "vue";
const value = 0;
const images = [
  'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/cd7a1aaea8e1c5e3d26fe2591e561798.png~tplv-uwbnlip3yd-webp.webp',
  'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6480dbc69be1b5de95010289787d64f1.png~tplv-uwbnlip3yd-webp.webp',
  'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/0265a04fddbd77a19602a15d9d55d797.png~tplv-uwbnlip3yd-webp.webp',
];
const fileHandle = (type:string) => {
  console.log(type);
  router.push("/fileHandle/"+type);

}

// 使用 ref 创建响应式引用
const targetElement = ref<HTMLElement | null>(null);

// 定义滚动到目标元素的方法
const jumpToContainers = () => {
  if (targetElement.value) {
    targetElement.value.scrollIntoView({ behavior: 'smooth' });
  }
};
const replay = (e:any, animClass:any) => {
  const el = e.currentTarget;
  const targetClass = el.classList[1];
  if(!targetClass){
    el.classList.add(animClass);
  }else{
    el.classList.remove(targetClass);
    setTimeout(() => {
      el.classList.add(targetClass);
    },1000)
  }
}





</script>
<style scoped>
.banner {
  overflow: hidden;
  text-align: center;
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  background-image: url(@/assets/svg/banner/background1.svg);
  background-repeat: repeat;
  background-size: 100%;
  margin-bottom: 60px;
}
.banner-main {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  justify-content: center;
  position: relative;
  text-align: center;
}
.card-file {
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
}


</style>