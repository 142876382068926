<template>
  <div class="container-demo">
    <el-container class="containerElement">
      <el-header>
        <el-menu :router="true"
            :default-active="activeIndex"
            class="el-menu-vertical"
            @select="handleSelect"
            mode="horizontal"
        >
          <el-menu-item disabled style="opacity: 1">
            <div
                :style="{
                  width: '60px',
                  height: '60px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }"
            >

              <a class="navbar-brand" href="/">
                <img style="height: 30px;width: 30px" src="@/assets/snailLogo.png" alt="">
                <strong>arSnail</strong>
              </a>
            </div>
          </el-menu-item>
<!--          <el-menu-item  index="/test">-->
<!--              测试-->
<!--          </el-menu-item>-->
<!--          <el-menu-item index="1">主页</el-menu-item>-->
        </el-menu>
      </el-header>
      <el-main style="padding: 0;background-color: #f9f9f9;">
        <div class="main-content">
          <div>
            <router-view></router-view>
          </div>
        </div>
      </el-main>
      <el-footer style="margin-top: 45px">
        Copyright © 2023 Arjun Design (如有侵权，联系QQ:970782339)
                  <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023022206号</a>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import {routes} from "@/router";
import HeaderView from "@/layouts/HeaderView.vue";

const router = useRouter();
const store = useStore();

const activeIndex = ref('1')
const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath)
}

// 展示在菜单的路由数组
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    console.log(item.meta?.hideInMenu);
    if (item.meta?.hideInMenu) {
      return false;
    }
    return true;
  });
});

// 默认主页
const selectedKeys = ref("/");

// 路由跳转后，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = to.path;
});


const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
.containerElement {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  width: 100%;
  margin: 0 auto;
}
.navbar-brand {
  text-decoration: none;
  color: #fae605;
  margin: 0 auto;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none; }

.main-content {
  flex: 1; /* 使主要内容占据剩余空间 */
  padding: 0;
  width: 100%;
}
.el-menu-vertical {
  position: fixed;
  top: 0;
  left: 0; /* 或者 right: 0; 根据你的布局需求 */
  width: 100%; /* 或者具体的宽度值 */
  z-index: 999; /* 确保菜单在其他内容之上 */
  /* 其他必要的样式，如背景色、边框等 */
  /* 添加底部阴影 */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}


</style>